/**
 * Created by Liu.Jun on 2019/9/29 19:01.
 */


import propsSchema from './CarouselImg.json';
import uiSchema from './uiSchema.js';
import errorSchema from './errSchema.js';
import mixin from '@/api/index.js';
const View = () => import('./View.vue');

setTimeout(()=>{
	mixin.methods.requset('/b2bgoods/selectSpecialByPid',{typeid:1}).then((res)=> {
		let enumNames=[]
		let enums=[]
		res.data.map(resb=>{
			enumNames.push(resb.specialname)
			enums.push(resb.id)
		})
		sessionStorage.setItem('biglist',JSON.stringify(res.data))
		console.log(1111111111111111+'biglist',propsSchema.properties)
		
		
			propsSchema.properties.SelectWidget.enum=enums
			propsSchema.properties.SelectWidget.enumNames=enumNames

		console.log('mixin',propsSchema)

	});
},2500)
export default {
    View,
    propsSchema,
	errorSchema,
    uiSchema
};
