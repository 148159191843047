/**
 * Created by Liu.Jun on 2019/9/29 19:01.
 */

// import propsSchemas from './propsSchema.js';
import propsSchema from './CarouselImg.json';
import uiSchema from './uiSchema.js';
import errorSchema from './errSchema.js';

const View = () => import('./View.vue');

export default {
    View,
    propsSchema,
	// propsSchemas,
	errorSchema,
    uiSchema
};
