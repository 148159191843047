/**
 * Created by Liu.Jun on 2019/9/29 19:01.
 */

import propsSchema from './propsSchema.js';
import uiSchema from './uiSchema.js';
import mixin from '@/api/index.js';
const View = () => import('./View.vue');

setTimeout(()=>{
	mixin.methods.requset('/b2bgoods/selectSpecialByPid',{typeid:6}).then((res)=> {
		let enumNames=[]
		let enums=[]
		res.data.map(resb=>{
			enumNames.push(resb.specialname)
			enums.push(resb.id)
		})
		propsSchema.properties.SelectWidget.enum=enums
		propsSchema.properties.SelectWidget.enumNames=enumNames
	});
},2500)

export default {
    View,
    propsSchema,
    uiSchema
};
