/**
 * Created by Liu.Jun on 2020/4/30 17:40.
 */

import genImgItem from '../_commonConfig/error/genImgItem';

const errorItemSchema = genImgItem();

export default {
    imgItem1_1: errorItemSchema,
    imgItem2_1: errorItemSchema,
    imgItem2_2: errorItemSchema,
    imgItem2_3: errorItemSchema
};
