/**
 * Created by Liu.Jun on 2019/9/29 19:01.
 */


import propsSchema from './CarouselImg.json';
import uiSchema from './uiSchema.js';
import errorSchema from './errSchema.js';
import mixin from '@/api/index.js';

const View = () => import('./View.vue');


// setTimeout(()=>{
// 	mixin.methods.requset('/b2bgoods/selectSpecialByPid',{typeid:2}).then((res)=> {
// 		let enumNames=[]
// 		let enums=[]
// 		res.data.map(resb=>{
// 			enumNames.push(resb.specialname)
// 			enums.push(resb.id)
// 		})
// 		sessionStorage.setItem('smalllist',JSON.stringify(res.data))
// 		console.log(999999,propsSchema.properties)
		
// 			propsSchema.properties.SelectWidget.enum=enums
// 			propsSchema.properties.SelectWidget.enumNames=enumNames
			
// 			propsSchema.properties.SelectWidget1.enum=enums
// 			propsSchema.properties.SelectWidget1.enumNames=enumNames
		
		
// 		console.log('mixin',propsSchema)

// 	});
// },2500)





// },2000)

export default {
    View,
    propsSchema,
	errorSchema,
    uiSchema,
};
