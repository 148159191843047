/**
 * Created by Liu.Jun on 2020/7/24 10:00 下午.
 */
export default {
    imgList: {
        items: {
            imgLink: {
                'err:format': '请输入正确的的链接地址',
                'err:required': '不能为空'
            }
        }
    }
};
