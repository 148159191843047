/**
 * Created by Liu.Jun on 2020/7/24 10:00 下午.
 */
	// "required":["SelectWidget","SelectWidget1"],
export default {
    SelectWidget: {
        // items: {
        //     imgLink: {
        //         'err:format': '请输入正确的的链接地址',
        //         'err:required': '不能为空'
        //     }
        // }
    }
};

// "SelectWidget": {
		// 	"title": "选择专场1",
		// 	"type": "number",
		// 	"ui:widget": "SelectWidget",
		// 	"enum": ["1","2","3"],
		// 	"enumNames": ["一","二","三"]
		// },
		// "SelectWidget1": {
		// 	"title": "选择专场2",
		// 	"type": "number",
		// 	"ui:widget": "SelectWidget",
		// 	"enum": ["1","2","3"],
		// 	"enumNames": ["一","二","三"]
		// },
		
		// "title1": {
		//     "title": "标题2",
		//     "type": "string",
		//     "ui:placeholder": "输入你的内容",
		//     "err:required": "必须输入标题文字内容",
		// 	"ui:size":"medium",
		// 	"default":"中药饮片",
		
		// 	    "layoutColumn": 2,
		// 	    "labelPosition": "left",
		// 	    "labelWidth": "100px",
		// 	    "labelSuffix": "："
			  
		// },
		// "title2": {
		//     "title": "标题2",
		//     "type": "string",
		//     "ui:placeholder": "输入你的内容",
		//     "err:required": "必须输入标题文字内容",
		// 	"ui:size":"medium"
		// }