/**
 * Created by Liu.Jun on 2020/4/30 17:13.
 */

import genImgItem from '../_commonConfig/ui/genImgItem';
const line2Item = genImgItem({
    width: 383,
    height: 500,
});
export default {
    imgList: {
        'ui:options': {
            title: '添加图片',
            description: '图片宽度372px，高度固定160px。',
            showIndexNumber: true
        },
        items: {
            ...genImgItem({
                width: 1220,
                height: 500,
            }),
            'ui:title': '品牌专区图片设置'
        }
    },
};
